import React, { Component } from "react";
import Contacto from "./Contacto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faConnectdevelop,
  faCodepen,
  faHtml5,
  faCss3,
  faPhp,
  faReact,
  faAngular,
  faJsSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCode,
  faCloudUploadAlt,
  faBraille,
  faEllipsisH,
  faDatabase,
  faUser,
  faEnvelope,
  faPhone,
  faComment,
  faAt,
  faPhoneSquare,
  faMapMarker,
  faEnvelopeSquare,
  faMapMarkerAlt,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
// CSS
import "../components/styles/BodyIndex.css";
// Images Projects
import card_websites_img from "../images/wd/rwd21.jpg";
import card_webapps_img from "../images/bg14.jpg";
// Images Tech
import card_tech_html5_logo from "../images/tech/html5_logo.png";
import card_tech_css3_logo from "../images/tech/css3_logo.png";
import card_tech_javascript_logo from "../images/tech/javascript_logo.png";
import card_tech_rwd_logo from "../images/tech/rwd_logo.png";
import card_tech_jquery_logo from "../images/tech/jquery_logo.png";
import card_tech_jquerymobile_logo from "../images/tech/jquerymobile_logo.png";
import card_tech_react_logo from "../images/tech/react_logo.png";
import card_tech_bootstrap_logo from "../images/tech/bootstrap_logo.png";
import card_tech_ajax_logo from "../images/tech/ajax_logo.png";
import card_tech_php_logo from "../images/tech/php_logo3.png";
import card_tech_sql_logo from "../images/tech/sql_logo.png";
import card_tech_mysql_logo from "../images/tech/mysql_logo4.png";
import card_tech_json_logo from "../images/tech/json_logo2.png";
import card_tech_git_logo from "../images/tech/git_logo.png";
import card_tech_github_logo from "../images/tech/github_logo.png";
import card_tech_gitlab_logo from "../images/tech/gitlab_logo3.png";
import contactenos_logo from "../images/contactenos.png";

const IndexBodyEs = () => (
  <React.Fragment>
    {/* Inicio */}
    <div className="bg_index" id="inicio">
      <div className="container-fluid caption" id="p1">
        <div className="row">
          <div className="col-sm">
            <p>
              <a className="btn btn-outline-light p-3 mr-4" href="#proyectos">
                Comenzar
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* Proyectos */}
    <div className="pt-5 pb-5 bg-light" id="proyectos">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-center font-weight-light">Proyectos</h3>
            <p className="text-center" style={{ color: "#31859c" }}>
              <FontAwesomeIcon icon={faConnectdevelop} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faCode} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faCodepen} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faBraille} size="lg" />
              <br />
              <span>
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  size="lg"
                  className="text-secondary"
                />
              </span>
            </p>
            <h4 className="text-center text-secondary font-weight-light">
              <i className="fas fa-quote-left"></i>&nbsp;&nbsp;Desarrollamos
              Software Personalizado, integrando tecnologías de última
              generación, creando aplicaciones web que permiten dar solución a
              necesidades específicas de empresas públicas, privadas y
              profesionales de cualquier sector&nbsp;&nbsp;
              <i className="fas fa-quote-right"></i>
            </h4>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card-deck">
              <div className="card">
                <img
                  className="card-img-top"
                  src={card_websites_img}
                  alt="Websites Development"
                />
                <div className="card-body bg-info text-white">
                  <h5 className="card-title">Desarrollo de Páginas Web</h5>
                  <p className="card-text">
                    Diseño responsivo; adaptable a todos los tamaños de
                    pantalla.
                  </p>
                  <p className="card-text">
                    <small>
                      <a className="card-link text-light" href="#contacto">
                        Contacto
                      </a>
                    </small>
                  </p>
                </div>
              </div>
              <div className="card">
                <img
                  className="card-img-top"
                  src={card_webapps_img}
                  alt="Applications Web Development"
                />
                <div className="card-body bg-dark text-white">
                  <h5 className="card-title">Desarrollo de Aplicaciones Web</h5>
                  <p className="card-text">
                    Cualquier idea, cualquier proyecto, jppcode lo desarrolla,
                    en la nube, para ser usado desde cualquier lugar, desde
                    cualquier dispositivo.&nbsp;&nbsp;Información en tiempo
                    real.
                  </p>
                  <p className="card-text">
                    <small>
                      <a className="card-link text-light" href="#contacto">
                        Contacto
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Tecnologías */}
    <div className="pt-5 pb-5 bg-light border border-muted" id="tecnologias">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-center font-weight-light">Tecnologías</h3>
            <p className="text-center" style={{ color: "#31859c" }}>
              <FontAwesomeIcon icon={faHtml5} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faCss3} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faJsSquare} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faPhp} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faDatabase} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faReact} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faAngular} size="lg" />
              <br />
              <span>
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  size="lg"
                  className="text-secondary"
                />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_html5_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_css3_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_javascript_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_rwd_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_jquery_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_jquerymobile_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_react_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_bootstrap_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_ajax_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_php_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_sql_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_mysql_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_json_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_git_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_github_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
          <div className="col-3 card m-1 card_tech_div">
            <img
              src={card_tech_gitlab_logo}
              className="card-img-top img-fluid card_tech_img"
            />
          </div>
        </div>

        <br />
      </div>
    </div>

    {/* Contacto */}
    <Contacto />
    <div>
      <a href="#inicio">
        <span
          className="ir-arriba pr-3 pl-5"
          id="goup"
          style={{ display: "none" }}
        >
          <FontAwesomeIcon icon={faAngleUp} size="5x" />
        </span>
      </a>
    </div>
  </React.Fragment>
);

export default IndexBodyEs;
