import React, { Component } from "react";

const newDate = new Date();
const currentYear = newDate.getFullYear();

const Footer = () => (
  <footer className="container-fluid bg-light" style={{ opacity: "1" }}>
    <div className="row">
      <div className="col-12">
        <p
          id="jpp_p_footer"
          className="text-center mt-2 mb-2"
          style={{ color: "#000", opacity: "1", userSelect: "none" }}
        >
          Copyright &copy; {currentYear} jppcode
        </p>
      </div>
    </div>
  </footer>
);
// var camarones = document.getElementById("jpp_p_footer");
document.getElementById("jpp_p_footer");

export default Footer;
