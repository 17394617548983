import React, { Component } from "react";

const Navbar = () => (
  <nav
    className="navbar navbar-light bg-light"
    style={{ userSelect: "none", opacity: "0.8" }}
  >
    <span className="navbar-brand mb-0">jppcode</span>
  </nav>
);

export default Navbar;
