import React, { Component } from "react";
import ReactDOM from "react-dom";
// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
// Components
import App from "./components/App.js";

const container = document.getElementById("root");

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  container
);
