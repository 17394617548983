import React, { Component, useState } from "react";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faPhoneSquare,
  faEnvelopeSquare,
  faMapMarkerAlt,
  faEllipsisH,
  faUser,
  faEnvelope,
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

// CONTACTO
const Contact = () => {
  const [datos, setDatos] = useState({
    jpp_c_nombre: "",
    jpp_c_email: "",
    jpp_c_telefono: "",
    jpp_c_mensaje: "",
    jpp_c_email_status: "",
  });

  const handleInputChange = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const enviarDatos = (event) => {
    event.preventDefault();

    console.log(
      datos.jpp_c_nombre +
        " - " +
        datos.jpp_c_email +
        " - " +
        datos.jpp_c_telefono +
        " - " +
        datos.jpp_c_mensaje
    );
  };

  // **************************************
  // **************************************
  // **************************************

  // **************************************
  // **************************************
  // **************************************

  // Contacto
  return (
    <React.Fragment>
      <div className="container-fluid pt-5 pb-5 bg-light" id="contacto">
        <div className="row">
          <div className="col">
            <h3 className="text-center font-weight-light">Contact</h3>
            <p className="text-center" style={{ color: "#31859c" }}>
              {/* <img src={contactenos_logo} height="25" /> */}
              {/* <br /> */}
              <FontAwesomeIcon icon={faAt} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faPhoneSquare} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faEnvelopeSquare} size="lg" />
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
              <br />
              <span>
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  size="lg"
                  className="text-secondary"
                />
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-6 pt-2 pb-2 rounded-top"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.65)",
            }}
          >
            <h4 className="text-white font-weight-light">Contact Form</h4>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-6 pt-4 pb-4 rounded-bottom"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Comienzo de FORMULARIO */}
            <form
              className="needs-validation"
              // noValidate
              // onSubmit={enviarDatos}
              onSubmit={sendEmail}
              onClick={submitButtonClick}
              id="contact_form_jppcode"
              // method="post"
              // role="form"
            >
              {/* Nombre */}
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="jpp_c_nombre"
                    name="jpp_c_nombre"
                    onChange={handleInputChange}
                    maxLength="60"
                    placeholder="Name ..."
                    required
                    autoComplete="off"
                    // value={datos.jpp_c_nombre}
                  />
                  <div className="valid-feedback pl-5">Looks Good!</div>
                  <div className="invalid-feedback pl-5">
                    Please enter your name.
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="jpp_c_email"
                    name="jpp_c_email"
                    onChange={handleInputChange}
                    maxLength="60"
                    placeholder="Enter email ..."
                    required
                    autoComplete="off"
                    // value={datos.jpp_c_email}
                  />
                  <div className="valid-feedback pl-5">Looks Good!</div>
                  <div className="invalid-feedback pl-5">
                    Please enter your email.
                  </div>
                </div>
              </div>
              {/* Teléfono */}
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="jpp_c_telefono"
                    name="jpp_c_telefono"
                    onChange={handleInputChange}
                    maxLength="30"
                    placeholder="Contact number ..."
                    required
                    autoComplete="offf"
                    // value={datos.jpp_c_telefono}
                  />
                  <div className="valid-feedback pl-5">Looks Good!</div>
                  <div className="invalid-feedback pl-5">
                    Please enter your contact number.
                  </div>
                </div>
              </div>
              {/* Mensaje */}
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faComment} />
                    </div>
                  </div>
                  <textarea
                    className="form-control"
                    id="jpp_c_mensaje"
                    name="jpp_c_mensaje"
                    onChange={handleInputChange}
                    maxLength="500"
                    placeholder="Message ..."
                    required
                    autoComplete="off"
                    // value={datos.jpp_c_mensaje}
                  ></textarea>
                  <div className="valid-feedback pl-5">Looks Good!</div>
                  <div className="invalid-feedback pl-5">
                    Please write your message.
                  </div>
                </div>
              </div>
              {/* Fecha Completa */}
              <input
                type="text"
                id="jpp_c_fecha"
                name="jpp_c_fecha"
                placeholder="Fecha"
                readOnly
                style={{ width: "100%", display: "none" }}
              />
              {/* Botones Submit and Reset */}
              <hr />
              <button
                className="btn btn-success shadow d-none"
                id="form_sent_button"
                disabled
              >
                Form Submitted
              </button>
              <button
                type="submit"
                className="btn btn-success shadow"
                id="button_send"
              >
                Send
              </button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <button
                type="reset"
                className="btn btn-secondary shadow"
                id="button_reset"
              >
                Clear
              </button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              {/* Spinner Sending */}
              <button
                className="btn btn-dark shadow d-none"
                id="sending_button"
                type="button"
                disabled
              >
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;&nbsp;Sending...
              </button>
            </form>

            {/* Success Mail Sent */}
            <div
              className="alert alert-success alert-dispose fade show mt-4 border border-success d-none"
              id="success_mail_sent"
            >
              {/* <button type="button" className="close" data-dismiss="alert">
                &times;
              </button> */}
              <strong>Message successfully sent!</strong>
              <br />
              <hr />
              Thanks for contact us, we'll answer you as soon as possible.
            </div>
            {/* ERROR Mail Sent */}
            <div
              className="alert alert-danger alert-dispose fade show mt-4 border border-danger d-none"
              id="error_mail_sent"
            >
              {/* <button type="button" className="close" data-dismiss="alert">
                &times;
              </button> */}
              <strong>Message wasn't sent !</strong>
              <br />
              <hr />
              Please check your internet connection and try again.
              <br />
              Thanks !!!
            </div>
          </div>

          <div className="col-md-3"></div>
        </div>
        <small>
          <p
            className="text-center"
            style={{ color: "#6c757d" }}
            id="final_msg"
          >
            We'll answer you as soon as possible
            <br />
            Thanks for visiting !
          </p>
        </small>
      </div>
    </React.Fragment>
  );
};

// Evento para que no aparezca required message
document.addEventListener('invalid', (function () {
    return function (e) {
        e.preventDefault();
    };
})(), true);

// Onclick agrega validación personalizada al formulario
function submitButtonClick() {
  const form_submit = document.getElementById("contact_form_jppcode");
  // document.getElementById("contact_form_jppcode").classList.add("contact_form_jppcode_validation");
  document.getElementById("contact_form_jppcode").classList.add("was-validated");
}

// Enviar Email con Datos del Formulario de Contacto
function sendEmail(e) {

  e.preventDefault();

  let jpp_c_fecha = new Date();
  document.getElementById("jpp_c_fecha").value = jpp_c_fecha;

  emailjs
    .sendForm(
      "default_service",
      "template_jppcode_contact_form",
      e.target,
      "user_FnKsoAXRlrWeIJda80qtG"
    )
    .then(
      (result) => {
        console.log(result.text);
        document.getElementById("button_send").classList.add("disabled");
        document.getElementById("sending_button").classList.remove("d-none");
        setTimeout(function () {
          document.getElementById("sending_button").classList.add("d-none");
        }, 3000);
        setTimeout(function () {
          document
            .getElementById("success_mail_sent")
            .classList.remove("d-none");
          document.getElementById("button_reset").click();
          document
            .getElementById("contact_form_jppcode")
            .classList.remove("was-validated");
          document
            .getElementById("jpp_c_nombre")
            .setAttribute("disabled","");
          document
            .getElementById("jpp_c_email")
            .setAttribute("disabled","");
          document
            .getElementById("jpp_c_telefono")
            .setAttribute("disabled","");
          document
            .getElementById("jpp_c_mensaje")
            .setAttribute("disabled","");
        }, 4000);
        setTimeout(function () {
          document
            .getElementById("success_mail_sent")
            .classList.add("d-none");
        }, 10000);
        setTimeout(function () {
          document.getElementById("final_msg").style.color = "#6610f2";
          document.getElementById("button_send").classList.add("d-none");
          document
            .getElementById("form_sent_button")
            .classList.remove("d-none");
        }, 11000);
      },
      (error) => {
        // alert(error.text);
        console.log(error.text);
        document.getElementById("sending_button").classList.remove("d-none");
        setTimeout(function () {
          document.getElementById("sending_button").classList.add("d-none");
        }, 3000);
        setTimeout(function () {
          document
            .getElementById("error_mail_sent")
            .classList.remove("d-none");
          document.getElementById("button_reset").click();
        }, 4000);
        setTimeout(function () {
          document.getElementById("error_mail_sent").classList.add("d-none");
        }, 10000);
      }
    );

}

export default Contact;
