import React, { Component } from "react";
import { Link } from "react-router-dom";

const NavbarEn = () => (
  <nav
    className="navbar navbar-expand-sm fixed-top navbar-light bg-light"
    style={{ background: "transparent", opacity: "0.8" }}
  >
    <a className="navbar-brand" href="#inicio">
      jppcode
    </a>
    {/* Icono de Navegación cuando xs sm md */}
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarjppcode"
      aria-controls="navbarjppcode"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarjppcode">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <a className="nav-link" href="#inicio">
            Home<span className="sr-only">(current)</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#proyectos">
            Projects
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#tecnologias">
            Technologies
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#contacto">
            Contact
          </a>
        </li>
      </ul>
      <span className="nav-text text-right">
        <Link to="/es" className="nav-link text-secondary">
          Español
        </Link>
      </span>
    </div>
  </nav>
);

export default NavbarEn;
