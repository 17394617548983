import React, { Component } from "react";
import { Link } from "react-router-dom";
// Styles
import "../components/styles/Main.css";
// Components
import Navbar from "../components/Navbar";
import FooterMain from "../components/FooterMain";

const newDate = new Date();
const currentYear = newDate.getFullYear();

const Main = () => (
  <div className="bg">
    <Navbar />

    <div className="container-fluid caption">
      <div className="row">
        <div className="col-sm">
          <p>
            <Link to="/es" className="btn btn-outline-light p-3 mr-4">
              Español
            </Link>
            <Link to="/en" className="btn btn-outline-light p-3 ml-4">
              English
            </Link>
          </p>
          <p className="text-light">A software development company ...</p>
        </div>
      </div>
    </div>

    <FooterMain />
  </div>
);

export default Main;
