import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "../pages/Main";
import IndexEs from "../pages/IndexEs";
import IndexEn from "../pages/IndexEn";
import NotFound from "../pages/404";
// import NotFound from "../pages/NotFound";

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/es" component={IndexEs} />
      <Route exact path="/en" component={IndexEn} />
      <Route component={NotFound} />
      {/* <Route component={NotFound} /> */}
    </Switch>
  </BrowserRouter>
);

export default App;
