import React from "react";
import "../components/styles/Errors.css";
import NotFoundImg from "../images/404.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const NotFound = () => (
  <React.Fragment>
    <Navbar />
    <div className="text-center">
      <h1 className="Error_Text">Error: 404 Page Not Found</h1>
      <img
        src={NotFoundImg}
        alt="404 NotFound"
        className="Error_Image img-fluid"
      />
    </div>
    <div className="text-center p-4">
      <a className="btn btn-light" href="https://jppcode.com/">
        Home
      </a>
    </div>
    <Footer />
  </React.Fragment>
);

export default NotFound;
