import React from "react";
// components
import NavbarEs from "../components/NavbarEs.js";
import Footer from "../components/Footer.js";
import IndexBodyEs from "../components/IndexBodyEs.js";

const IndexEs = () => (
  <React.Fragment>
    <NavbarEs />
    <IndexBodyEs />
    <Footer />
  </React.Fragment>
);

export default IndexEs;
