import React from "react";
// components
import NavbarEn from "../components/NavbarEn.js";
import Footer from "../components/Footer.js";
import IndexBodyEn from "../components/IndexBodyEn.js";

const IndexEs = () => (
  <React.Fragment>
    <NavbarEn />
    <IndexBodyEn />
    <Footer />
  </React.Fragment>
);

export default IndexEs;
